import React from "react"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TrainingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "schießstand.jpg" }) {
        childImageSharp {
          fixed(width: 550, height: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Training" lang="de" />
      <Container>
        <h1 className="h3">Training</h1>
        <hr className="featurette-divider" />
        <Row className="align-items-center">
          <Col xs={6}>
            <div>
              <p className="font-weight-bold">Luftdruckwaffen:</p>
              <p>
                <span className="font-italic">Schüler- und Jugendklasse</span>:
                Montags ab 18 Uhr <br />
                <span className="font-italic">Schützenklasse</span>: Freitags ab
                20 Uhr
              </p>
              <p>im Schützenhaus Gambach</p>
            </div>
            <div className="mt-4">
              <p className="font-weight-bold">Kleinkaliber:</p>
              <p>
                <span className="font-italic">Schützenklasse</span>: Donnerstags
                ab 20 Uhr
              </p>
              <p>auf dem Schießstand des Schützenvereins Hüttenberg</p>
            </div>
          </Col>
          <Col xs={6}>
            <Img fixed={data.file.childImageSharp.fixed} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default TrainingPage
